import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import RadioMuiGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, Span } from '../../../ui';
//////////////////////////////////////////////////

const Label = styled.label`
  display: block;
  cursor: pointer;
  user-select:none;
  text-align: left;

  &+.radio {
    margin-top: 12px;
  }

  input {
    display: none;

    &+span {
      position: relative;
      padding-left: 25px;
      display: inline-block;

      &:before {
        top: 0px;
        left: 0px;
        width: 16px;
        content: '';
        height: 16px;
        display: block;
        background: #fff;
        border: 1px solid;
        margin-right: 5px;
        border-radius: 50%;
        position: absolute;
        border-color: ${R.path(['theme', 'colors', '#7D828C'])};
        transition: all .3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }

      &:after {
        top: 4px;
        left: 4px;
        opacity: 0;
        width: 10px;
        content: '';
        height: 10px;
        display: block;
        position: absolute;
        border-radius: 50%;
        transform: scale(0,0);
        background: ${R.path(['theme', 'colors', 'white'])};
        transition: all .3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }

    &: checked+span:before {
      background: ${R.path(['theme', 'colors', 'light', 'blue'])};
    }

    &: checked+span:after {
      opacity: 1;
      transform: scale(1,1);
    }
  }
`;

const defaultOptions = [
  {
    value: true,
    label: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    value: false,
    label: G.getWindowLocale('titles:no', 'No'),
  },
];

const RadioGroup = ({
  value,
  options,
  fieldName,
  setFieldValue,
  radioGroupWrapperStyles,
  radioGroupLabelSpanStyles,
}: Object) => (
  <Flex mt={10} width={150} justifyContent='space-between' {...G.spreadUiStyles(radioGroupWrapperStyles)}>
    {R.or(options, defaultOptions).map(({ label, value: optionValue }: Object, index: number) => (
      <Label key={index}>
        <input
          type='radio'
          id={fieldName}
          name={fieldName}
          value={optionValue}
          checked={R.equals(value, optionValue)}
          onChange={() => setFieldValue(fieldName, optionValue)}
        />
        <Span
          color={G.ifElse(R.equals(value, optionValue), 'light.black', '#999999')}
          {...G.spreadUiStyles(radioGroupLabelSpanStyles)}
        >
          {label}
        </Span>
      </Label>
    ))}
  </Flex>
);

const RadioGroupMui = ({
  value,
  options,
  fieldName,
  setFieldValue,
}: Object) => (
  <RadioMuiGroup
    value={value}
    sx={{
      width: 150,
      marginTop: '5px',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    {R.or(options, defaultOptions).map(({ label, value: optionValue }: Object, index: number) => (
      <FormControlLabel
        key={index}
        label={label}
        value={optionValue}
        onChange={() => setFieldValue(fieldName, optionValue)}
        control={<Radio sx={{ color: G.getTheme('colors.dark.grey') }} />}
        sx={{ color: G.getThemeByCond(R.equals(value, optionValue), 'colors.light.black', 'colors.#999999') }}
      />
    ))}
  </RadioMuiGroup>
);

export {
  RadioGroup,
  RadioGroupMui,
};
