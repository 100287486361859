import * as R from 'ramda';
import { getIn } from 'formik';
import Dropzone from 'react-dropzone';
import { pure } from 'react-recompose';
import React, { Fragment } from 'react';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
// components
import { Switcher } from '../../../components/switcher';
import { TextComponent } from '../../../components/text';
import ColorPicker from '../../../components/color-picker';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { PHONE_COUNTRIES } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Toggle } from '../../restyled';
import { Box, Flex } from '../../../ui';
// features
import { CalendarInput } from '../../../features/new-do/forms/formik/calendar-time-input';
import {
  EditorComponent,
} from '../../../features/configurations/config-pages/communication/components/editor-component';
// forms
import CountrySelect from './country-select';
import PasswordInput from './password-input';
import MultiEmailInput from './multi-email-input';
import MultiItemsInput from './multi-items-input';
import { RadioGroup, RadioGroupMui } from './radio';
import PlaceAutocomplete from './place-autocomplete';
import { DateTimeInput, MaskedTimeInput } from './date-time-input';
import ReactSelectInput, { AsyncReactSelectInput } from './react-select-input';
import {
  InputWrapper,
  ToggleWrapper,
  CheckboxWrapper,
  InputWrapperWithClickZIndex,
} from './input-wrapper';
import {
  Input,
  Checkbox,
  Textarea,
  InputSelect,
  SelectWrapper,
  renderBorderColor,
  CalendarIconWrapper,
  CountrySelectWrapper,
  PhoneNumberInputWrapper,
} from './ui';
//////////////////////////////////////////////////

const renderOptions = (options: Array) => {
  if (R.isNil(options)) return;

  return options.map((option: any, index: number) => {
    if (G.isString(option)) {
      return <option key={index} value={option}>{option}</option>;
    }

    const { name, guid, value, label, disabled } = option;

    return (
      <option key={index} disabled={disabled} value={R.or(value, guid)}>
        {R.or(name, label)}
      </option>
    );
  });
};

const getInputWrapperVisibility = (props: any) => {
  const visibility = R.path(['inputWrapperStyles', 'visibility'], props);

  if (G.isBoolean(visibility)) return visibility;

  if (G.isFunction(visibility)) return visibility(props);

  return undefined;
};

const setInputWrapperVisibilityAndDisplay = (props: any) => {
  const { inputWrapperStyles } = props;

  const visibility = getInputWrapperVisibility(props);
  const wrapperDisplay = R.path(['inputWrapperStyles', 'display'], props);

  if (G.isFunction(wrapperDisplay)) {
    const display = wrapperDisplay(props);

    return {
      ...inputWrapperStyles,
      display,
      visibility,
    };
  }

  return {
    ...inputWrapperStyles,
    visibility,
  };
};

const getInputWrapperStyles = (props: Object) => {
  const { calcZIndex, arrayLength, rowMapIndex, fieldsetType } = props;

  if (R.and(G.isTrue(calcZIndex), R.equals(fieldsetType, 'array'))) {
    const zIndex = R.subtract(R.add(11, arrayLength), rowMapIndex);

    return {
      ...setInputWrapperVisibilityAndDisplay(props),
      zIndex,
    };
  }

  return setInputWrapperVisibilityAndDisplay(props);
};

const getIsRequired = (props: Object) => {
  const { isRequired } = props;

  if (G.isFunction(isRequired)) {
    return isRequired(props);
  }

  return isRequired;
};

const getInfoText = (props: Object) => {
  const { info, infoFunction } = props;

  if (G.isAllNilOrEmpty([info, infoFunction])) return null;

  if (G.isFunction(infoFunction)) return infoFunction(props);

  return info;
};

const FieldComponent = pure((props: Object) => {
  const {
    name,
    type,
    label,
    value,
    error,
    options,
    errorTop,
    hasError,
    errorWidth,
    labelStyles,
    setFieldValue,
    setFieldTouched,
    withClickZIndex,
    showFieldConfig,
    useMultiSelectMaxHeight,
    additionalLabelComponent,
  } = props;

  if (G.isNotNil(showFieldConfig)) {
    const showField = G.getAmousConfigByNameFromWindow(showFieldConfig);

    if (G.isFalse(showField)) return null;
  }

  const formikProps = R.pick([
    'values',
    'setValues',
    'setFieldError',
    'initialValues',
    'setFieldValue',
    'setFieldTouched',
  ], props);

  const simpleInputProps = R.pick([
    'id',
    'type',
    'name',
    'value',
    'onBlur',
    'onChange',
    'disabled',
    'isMobile',
    'hasError',
    'maxLength',
    'minHeight',
    'placeholder',
  ], props);

  const width = R.path(['inputWrapperStyles', 'width'], props);

  const inputStyles = R.mergeRight({ width: '100%' }, R.pathOr({}, ['inputStyles'], props));

  const additionalLabelStyles = R.mergeRight({ top: -18, right: 5 }, R.pathOr({}, ['additionalLabelStyles'], props));

  const infoStyles = R.mergeRight(
    { ml: 5, width: 14, height: 14, bottom: 2, minWidth: 14 },
    R.pathOr({}, ['infoStyles'], props),
  );

  const wrapperStyles = {
    width,
    error,
    label,
    errorTop,
    hasError,
    infoStyles,
    errorWidth,
    labelStyles,
    additionalLabelStyles,
    additionalLabelComponent,
    infoText: getInfoText(props),
    isRequired: getIsRequired(props),
    inputWrapperStyles: getInputWrapperStyles(props),
  };

  if (R.equals(type, 'textarea')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <Textarea {...simpleInputProps} {...G.spreadUiStyles(inputStyles)} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'password')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <PasswordInput {...simpleInputProps} {...G.spreadUiStyles(inputStyles)} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'checkbox')) {
    return (
      <CheckboxWrapper {...wrapperStyles}>
        <Checkbox {...simpleInputProps} checked={value} {...G.spreadUiStyles(inputStyles)} />
      </CheckboxWrapper>
    );
  }

  if (R.equals(type, 'toggle')) {
    return (
      <ToggleWrapper {...wrapperStyles}>
        <Toggle
          {...simpleInputProps}
          icons={false}
          checked={G.ifElse(
            G.isNotNilAndNotEmpty(props.checked),
            props.checked,
            props.value,
          )}
        />
      </ToggleWrapper>
    );
  }

  if (R.equals(type, 'select')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <SelectWrapper>
          <InputSelect {...simpleInputProps} {...G.spreadUiStyles(inputStyles)}>
            {renderOptions(options)}
          </InputSelect>
        </SelectWrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'reactSelect')) {
    const reactSelectProps = R.pick([
      'id',
      'value',
      'options',
      'isMulti',
      'sortable',
      'onChange',
      'isGrouped',
      'components',
      'placeholder',
      'valueComponent',
      'getOptionLabel',
      'shouldValidate',
      'formatOptionLabel',
      'closeMenuOnSelect',
      'closeMenuOnScroll',
      'shouldCustomChange',
      'withoutFixedHeight',
      'useMenuPortalTarget',
      'valueContainerMaxHeight',
      'reactSelectAdditionalStyles',
    ], props);

    const disabled = R.or(
      R.pathEq('hidden', ['inputWrapperStyles', 'visibility'], wrapperStyles),
      R.pathOr(false, ['disabled'], props),
    );

    if (G.isTrue(withClickZIndex)) {
      return (
        <InputWrapperWithClickZIndex {...wrapperStyles}>
          <Box {...G.spreadUiStyles(inputStyles)}>
            <ReactSelectInput
              {...reactSelectProps}
              {...formikProps}
              hasError={hasError}
              disabled={disabled}
              useMaxHeight={useMultiSelectMaxHeight}
            />
          </Box>
        </InputWrapperWithClickZIndex>
      );
    }

    return (
      <InputWrapper {...wrapperStyles}>
        <Box {...G.spreadUiStyles(inputStyles)}>
          <ReactSelectInput
            {...reactSelectProps}
            {...formikProps}
            hasError={hasError}
            disabled={disabled}
            useMaxHeight={useMultiSelectMaxHeight}
          />
        </Box>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'asyncSearch')) {
    const reactSelectProps = R.pick([
      'id',
      'value',
      'showIcon',
      'placeholder',
      'getLoadOptions',
      'handleAsyncSearchSelectChange',
    ], props);

    const defaultInputStyles = {
      width: '100%',
    };

    const searchSelectStyles = R.mergeRight(defaultInputStyles, R.or(inputStyles, {}));

    return (
      <InputWrapper {...wrapperStyles}>
        <AsyncReactSelectInput
          {...searchSelectStyles}
          {...reactSelectProps}
          {...formikProps}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'multiEmail')) {
    const inputProps = R.pick([
      'id',
      'value',
      'disabled',
      'hasError',
      'shouldOverrideEmails',
    ], props);

    const defaultInputStyles = {
      minHeight: 36,
      borderRadius: '4px',
      borderColor: G.getTheme('colors.dark.grey'),
    };

    const multiEmailStyles = R.mergeRight(defaultInputStyles, R.or(inputStyles, {}));

    return (
      <InputWrapper {...wrapperStyles}>
        <MultiEmailInput
          {...inputProps}
          {...formikProps}
          {...multiEmailStyles}
          width={R.propOr(width, 'multiEmailWidth', multiEmailStyles)}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'multiItems')) {
    const inputProps = R.pick([
      'id',
      'value',
      'disabled',
      'placeholder',
    ], props);

    const defaultInputStyles = {
      minHeight: 36,
      borderRadius: '4px',
      borderColor: G.getTheme('colors.dark.grey'),
    };

    const multiItemsStyles = R.mergeRight(defaultInputStyles, R.or(inputStyles, {}));

    return (
      <InputWrapper {...wrapperStyles}>
        <MultiItemsInput
          {...inputProps}
          {...formikProps}
          {...multiItemsStyles}
          width={width}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'addressAutocomplete')) {
    const placeProps = R.pick([
      'id',
      'value',
      'handlers',
      'disabled',
      'fieldType',
      'placeholder',
      'omitAddress1',
      'locationMapper',
      'additionalStyles',
      'addressFieldName',
      'closeMenuOnScroll',
      'shouldHandleEnter',
      'useMenuPortalTarget',
      'useFormattedAddress',
      'concatLocationFields',
      'customSelectLocationFunction',
    ], props);

    return (
      <InputWrapper {...wrapperStyles}>
        <PlaceAutocomplete
          {...placeProps}
          {...formikProps}
          hasError={hasError}
          width={inputStyles.width}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'countrySelect')) {
    const selectProps = R.pick([
      'id',
      'value',
      'disabled',
    ], props);

    return (
      <InputWrapper {...wrapperStyles}>
        <CountrySelectWrapper {...G.spreadUiStyles(inputStyles)} hasError={hasError}>
          <CountrySelect {...selectProps} {...formikProps} />
        </CountrySelectWrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'datePicker')) {
    const inputProps = R.pick([
      'minDate',
      'maxDate',
      'fromPage',
      'isClearable',
      'timeSelection',
      'disablePortal',
      'handleChangeInput',
      'shouldCustomChange',
      'customChangeHandler2',
    ], props);

    const configTimeIntervals = R.or(G.getCalendarTimeIntervalConfigFromWindow(), 15);
    const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);
    const configDateFormat = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);

    if (G.isFalse(configUseMuiCalendar)) {
      return (
        <InputWrapperWithClickZIndex {...wrapperStyles}>
          <CalendarIconWrapper width={R.pathOr(width, ['width'], inputStyles)}>
            <CalendarInput
              {...props}
              {...inputStyles}
              customInput={true}
              format={configDateFormat}
              timeIntervals={configTimeIntervals}
            />
          </CalendarIconWrapper>
        </InputWrapperWithClickZIndex>
      );
    }

    return (
      <InputWrapper {...wrapperStyles}>
        <DateTimeInput
          {...simpleInputProps}
          {...formikProps}
          {...inputProps}
          inputStyles={inputStyles}
          format={configDateFormat}
          timeIntervals={configTimeIntervals}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'timeInput')) {
    const inputProps = R.pick([
      'fromPage',
      'handleChangeInput',
    ], props);

    const configDateFormat = G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);

    return (
      <InputWrapper {...wrapperStyles}>
        <MaskedTimeInput
          {...simpleInputProps}
          {...formikProps}
          {...inputProps}
          inputStyles={inputStyles}
          format={configDateFormat}
          wrapperStyles={wrapperStyles}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'phoneNumber')) {
    const { phoneInputBorder, phoneInputPaddingLeft, phoneInputBorderRadius } = props;

    return (
      <InputWrapper {...wrapperStyles}>
        <PhoneNumberInputWrapper
          width='100%'
          hasError={hasError}
          phoneInputBorder={phoneInputBorder}
          phoneInputPaddingLeft={phoneInputPaddingLeft}
          phoneInputBorderRadius={phoneInputBorderRadius}
        >
          <PhoneInput
            country='US'
            error={null}
            flags={flags}
            value={value}
            countries={PHONE_COUNTRIES}
            onBlur={() => setFieldTouched(name, true)}
            onChange={(value: Object) => setFieldValue(name, R.or(value, ''))}
            placeholder={G.getWindowLocale('titles:enter-phone-number', 'Enter phone number')}
          />
        </PhoneNumberInputWrapper>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'file')) {
    const { width, height } = props;

    let fileName;

    if (G.isNotNilAndNotEmpty(value)) fileName = R.pathOr(value, [GC.FIELD_NAME], value);

    return (
      <InputWrapper
        {...wrapperStyles}
        border='1px dotted'
      >
        <Dropzone className='drop-zone' onDrop={(files: Array) => setFieldValue(name, R.head(files))}>
          {({ getRootProps, getInputProps }: Object) => (
            <Flex
              {...getRootProps()}
              px={15}
              cursor='pointer'
              borderRadius='4px'
              textAlign='center'
              width={props.width}
              border='1px dashed'
              wordBreak='break-word'
              justifyContent='center'
              height={R.or(height, 60)}
              borderColor={renderBorderColor(props)}
            >
              <input {...R.dissoc('multiple', getInputProps())} />
              Drag and Drop or select file from your Computer
            </Flex>
          )}
        </Dropzone>
        {
          G.isNotNilAndNotEmpty(fileName) &&
          <Flex px={15} mt={10} position='relative' width={width}>
            <TextComponent
              display='block'
              title={fileName}
              withEllipsis={true}
              maxWidth='calc(100% - 20px)'
            >
              {fileName}
            </TextComponent>
            <Box
              ml='7px'
              cursor='pointer'
              onClick={(event: Object) => {
                event.preventDefault();
                setFieldValue(name, null);
              }}
            >
              {I.trash(G.getTheme('colors.dark.blue'))}
            </Box>
          </Flex>
        }
      </InputWrapper>
    );
  }

  if (R.equals(type, 'files')) {
    const { width, height } = props;

    const fileNames = R.compose(
      R.filter(G.isNotNilAndNotEmpty),
      R.map(R.pathOr(null, [GC.FIELD_NAME])),
    )(R.or(value, []));

    return (
      <InputWrapper
        {...wrapperStyles}
        border='1px dotted'
      >
        <Dropzone
          className='drop-zone'
          onDrop={(files: Array) => setFieldValue(name, R.concat(value || [], files))}
        >
          {({ getRootProps, getInputProps }: Object) => (
            <Flex
              {...getRootProps()}
              px={15}
              cursor='pointer'
              borderRadius='4px'
              textAlign='center'
              width={props.width}
              border='1px dashed'
              wordBreak='break-word'
              justifyContent='center'
              height={R.or(height, 60)}
              borderColor={renderBorderColor(props)}
            >
              <input {...getInputProps()} />
              Drag and Drop or select files from your Computer
            </Flex>
          )}
        </Dropzone>
        {
          G.isNotNilAndNotEmpty(fileNames) &&
          <Flex
            px={15}
            mt={10}
            position='relative'
            width={width || '100%'}
            justifyContent='space-between'
          >
            <Flex
              flexDirection='column'
              alignItems='flex-start'
              maxWidth='calc(100% - 20px)'
            >
              {
                fileNames.map((fileName: string, i: number) => (
                  <Flex key={fileName} maxWidth='100%' justifyContent='space-between'>
                    <Box
                      mr={15}
                      cursor='pointer'
                      onClick={(event: Object) => {
                        event.preventDefault();

                        setFieldValue(name, R.remove(i, 1, value));
                      }}
                    >
                      {I.trash(G.getTheme('colors.dark.blue'))}
                    </Box>
                    <TextComponent
                      display='block'
                      maxWidth='100%'
                      title={fileName}
                      withEllipsis={true}
                    >
                      {fileName}
                    </TextComponent>
                  </Flex>
                ))
              }
            </Flex>
            <Box
              cursor='pointer'
              onClick={(event: Object) => {
                event.preventDefault();
                setFieldValue(name, null);
              }}
            >
              {I.trash(G.getTheme('colors.dark.blue'))}
            </Box>
          </Flex>
        }
      </InputWrapper>
    );
  }

  if (R.equals(type, 'editor')) {
    const { errors, values, touched, onChange, fieldName, shouldCustomChange } = props;

    const editorProps = {
      errors,
      touched,
      onChange,
      fieldName,
      wrapperStyles,
      setFieldValue,
      setFieldTouched,
      shouldCustomChange,
      value: R.pathOr('', [fieldName], values),
    };

    return <EditorComponent {...editorProps} />;
  }

  if (R.equals(type, 'colorPicker')) {
    const { fieldName } = props;

    const pickerProps = {
      value,
      fieldName,
      setFieldValue,
      itemStyles: R.path(['itemStyles'], inputStyles),
      wrapperStyles: R.path(['wrapperStyles'], inputStyles),
    };

    return (
      <InputWrapper {...wrapperStyles}>
        <ColorPicker {...pickerProps} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'customComponent')) {
    const { Component } = props;

    return (
      <InputWrapper {...wrapperStyles}>
        <Component {...props} />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'multiswitch')) {
    const { options, fieldName, setFieldValue, shouldCustomChange, customChangeHandler } = props;

    const handleSwitch = (value: string) => {
      if (shouldCustomChange) return customChangeHandler(value, props);

      return setFieldValue(fieldName, value);
    };

    return (
      <InputWrapper {...wrapperStyles}>
        <Switcher
          {...props}
          onSwitch={(value: Object) => handleSwitch(value)}
          selectedOptionIndex={R.findIndex(R.propEq(value, GC.FIELD_VALUE), R.or(options, []))}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'readonly')) {
    return (
      <InputWrapper {...wrapperStyles}>
        <Flex height={36} fontSize={12}>{value}</Flex>
      </InputWrapper>
    );
  }

  if (R.equals(type, 'radio')) {
    const { value, options, fieldName, setFieldValue, radioGroupWrapperStyles, radioGroupLabelSpanStyles } = props;

    return (
      <InputWrapper {...wrapperStyles}>
        <RadioGroup
          value={value}
          options={options}
          fieldName={fieldName}
          setFieldValue={setFieldValue}
          radioGroupWrapperStyles={radioGroupWrapperStyles}
          radioGroupLabelSpanStyles={radioGroupLabelSpanStyles}
        />
      </InputWrapper>
    );
  }

  if (R.equals(type, 'radioMui')) {
    const { value, options, fieldName, setFieldValue, radioGroupWrapperStyles, radioGroupLabelSpanStyles } = props;

    return (
      <InputWrapper {...wrapperStyles}>
        <RadioGroupMui
          value={value}
          options={options}
          fieldName={fieldName}
          setFieldValue={setFieldValue}
          radioGroupWrapperStyles={radioGroupWrapperStyles}
          radioGroupLabelSpanStyles={radioGroupLabelSpanStyles}
        />
      </InputWrapper>
    );
  }

  return (
    <InputWrapper {...wrapperStyles}>
      <Input {...simpleInputProps} {...G.spreadUiStyles(inputStyles)} />
    </InputWrapper>
  );
});

const setOptions = (item: Object, props: Object) => {
  const { options } = item;

  const { branchConfigs } = props;

  if (G.isArray(options)) {
    return options;
  }

  if (G.isObject(options)) {
    const { configName } = options;

    return G.createOptionsFromDropdownConfigWithGuidOrParentGuid(branchConfigs, configName, true);
  }

  if (G.isString(options)) {
    return R.prop(options, props);
  }

  return null;
};

const setDisabled = (field: any, values: Object, handlers: Object, props: Object) => {
  let disabled = field.disabled;

  if (G.isNotNilAndNotEmpty(field.customDisabledFunction)) {
    disabled = handlers[field.customDisabledFunction](values);
  }

  if (G.isFunction(field.disabled)) {
    disabled = field.disabled(field, values, props);
  }

  return disabled;
};

const getFieldValue = (fieldName: any, values: Object) => {
  if (R.isNil(fieldName)) return null;

  return R.path(R.split('.', fieldName), values);
};

const getFieldName = (item: Object, props: Object) => {
  const { arrayName, itemIndex, fieldsetType } = props;

  const { fieldName } = item;

  if (R.equals(fieldsetType, 'array')) {
    return `${arrayName}.${itemIndex}.${fieldName}`;
  }

  return fieldName;
};

const getFieldKey = (item: Object, props: Object, index: number) => {
  const { itemIndex, fieldsetType } = props;

  const { fieldName } = item;

  if (R.equals(fieldsetType, 'array')) {
    return `${index}.${itemIndex}.${fieldName}`;
  }

  return `${index}${fieldName}`;
};

const getFieldError = (errors: Object, name: string) => getIn(errors, name);

const checkHasFieldError = (name: string, errors: Object, touched: Object) => {
  const error = getFieldError(errors, name);
  const touch = getIn(touched, name);

  return R.and(G.isNotNilAndNotEmpty(error), R.or(G.isTrue(touch), G.isArray(touch)));
};

const getChangeHandler = (item: Object, props: Object) => {
  const { arrayItem, fieldsetType, handleChange, handleCustomChange } = props;

  const { shouldCustomChange, customChangeHandler } = item;

  if (R.and(G.isTrue(shouldCustomChange), G.isFunction(customChangeHandler))) {
    return (event: Object) => customChangeHandler(event, item, props);
  }

  const customChangeHandlerFromProps = G.getPropFromObject(customChangeHandler, props);

  if (G.isAllTrue(
    G.isTrue(shouldCustomChange),
    G.isFunction(customChangeHandlerFromProps),
    R.equals(fieldsetType, 'array'),
  )) {
    return (event: Object) => customChangeHandlerFromProps(event, arrayItem, props);
  }

  if (R.and(G.isTrue(shouldCustomChange), G.isFunction(handleCustomChange))) {
    return (e: Object) => handleCustomChange(e, item.fieldName);
  }

  return handleChange;
};

const getBlurHandler = (item: Object, props: Object) => {
  const { handleBlur } = props;

  const { shouldCustomBlur, customBlurHandler } = item;

  if (R.and(G.isTrue(shouldCustomBlur), G.isFunction(customBlurHandler))) {
    return (event: Object) => customBlurHandler(event, item, props);
  }

  return handleBlur;
};

const Fieldset2 = (props: Object) => {
  const {
    fields,
    values,
    errors,
    grouped,
    touched,
    errorTop,
    handlers,
    fromPage,
    setErrors,
    setValues,
    arrayItem,
    calcZIndex,
    arrayLength,
    rowMapIndex,
    fieldsetType,
    setFieldError,
    setFieldValue,
    branchConfigs,
    getLoadOptions,
    setFieldTouched,
    handleChangeInput,
    fieldsWrapperStyles,
    customSelectLocationFunction,
    handleAsyncSearchSelectChange,
    additionFieldComponentProps = [],
  } = props;

  if (G.isTrue(grouped)) {
    return (
      <Fragment>
        {
          fields.map((item: Object, index: number) => (
            <Flex
              key={index}
              flexWrap='wrap'
              {...G.spreadUiStyles(R.or(G.getPropFromObject('fieldsWrapperStyles', item), fieldsWrapperStyles))}
            >
              {
                item.fields.map((item: Object, index: number) => {
                  const fieldName = getFieldName(item, props);
                  const fieldError = getFieldError(errors, fieldName);
                  const hasError = checkHasFieldError(fieldName, errors, touched);
                  const key = getFieldKey(item, props, index);
                  const changeHandler = getChangeHandler(item, props);
                  const blurHandler = getBlurHandler(item, props);

                  return (
                    <FieldComponent
                      {...item}
                      {...R.pick(additionFieldComponentProps, props)}
                      key={key}
                      id={fieldName}
                      errors={errors}
                      values={values}
                      name={fieldName}
                      touched={touched}
                      error={fieldError}
                      handlers={handlers}
                      hasError={hasError}
                      fromPage={fromPage}
                      onBlur={blurHandler}
                      arrayItem={arrayItem}
                      setErrors={setErrors}
                      setValues={setValues}
                      calcZIndex={calcZIndex}
                      onChange={changeHandler}
                      arrayLength={arrayLength}
                      rowMapIndex={rowMapIndex}
                      fieldsetType={fieldsetType}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      branchConfigs={branchConfigs}
                      setFieldTouched={setFieldTouched}
                      options={setOptions(item, props)}
                      handleChangeInput={handleChangeInput}
                      value={getFieldValue(fieldName, values)}
                      disabled={setDisabled(item, values, handlers, props)}
                      // TODO: check moving to additionFieldComponentProps
                      getLoadOptions={getLoadOptions}
                      customSelectLocationFunction={customSelectLocationFunction}
                      handleAsyncSearchSelectChange={handleAsyncSearchSelectChange}
                    />
                  );
                })
              }
            </Flex>
          ))
        }
      </Fragment>
    );
  }

  return (
    <Flex flexWrap='wrap' {...G.spreadUiStyles(fieldsWrapperStyles)}>
      {
        fields.map((item: Object, index: number) => {
          const fieldName = getFieldName(item, props);
          const fieldError = getFieldError(errors, fieldName);
          const hasError = checkHasFieldError(fieldName, errors, touched);
          const key = getFieldKey(item, props, index);
          const changeHandler = getChangeHandler(item, props);
          const blurHandler = getBlurHandler(item, props);

          return (
            <FieldComponent
              {...item}
              {...R.pick(additionFieldComponentProps, props)}
              key={key}
              id={fieldName}
              errors={errors}
              values={values}
              name={fieldName}
              touched={touched}
              error={fieldError}
              handlers={handlers}
              hasError={hasError}
              fromPage={fromPage}
              errorTop={errorTop}
              onBlur={blurHandler}
              arrayItem={arrayItem}
              setErrors={setErrors}
              setValues={setValues}
              calcZIndex={calcZIndex}
              onChange={changeHandler}
              arrayLength={arrayLength}
              rowMapIndex={rowMapIndex}
              fieldsetType={fieldsetType}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              branchConfigs={branchConfigs}
              setFieldTouched={setFieldTouched}
              options={setOptions(item, props)}
              handleChangeInput={handleChangeInput}
              value={getFieldValue(fieldName, values)}
              disabled={setDisabled(item, values, handlers, props)}
              // TODO: check moving to additionFieldComponentProps
              getLoadOptions={getLoadOptions}
              customSelectLocationFunction={customSelectLocationFunction}
              handleAsyncSearchSelectChange={handleAsyncSearchSelectChange}
            />
          );
        })
      }
    </Flex>
  );
};

export {
  Fieldset2,
  getFieldError,
  checkHasFieldError,
};
